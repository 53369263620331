import * as React from "react";
import FAQSContainer from "../containers/faqs";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="Frequently Asked Questions">
        <FAQSContainer />
      </DefaultLayout>
    </>
  );
};
