import { Link } from "gatsby";
import React, { ReactElement } from "react";
import chevronDownIcon from "../../images/chevron-down.svg";
import FilledButton from "../filled-button";
interface IFaq {
  author?: string;
  title: string;
  description: string;
  bg?: string;
}
export interface FaqsProps { }

export default function Faqs(props: FaqsProps): ReactElement | null {

  const [openTabs, setOpenTabs] = React.useState([] as number[]);

  const faqs: IFaq[] = [
    {
      title: "What does www.lending-ground.com do?",
      description: `www.lending-ground.com is a loan introduction service which attempts to introduce your request details with a lender willing to lend to you. This service is FREE to the customer requesting. www.lending-ground.com may receive a commission from the lender for introducing the customer to the lender.`,
    },
    {
      title: "What is the minimum requirement for a loan?",
      description: `
      <ul style="list-style:bullet; padding-left:20px">
      <li>At least 18 years old</li>
      <li>A bank account with direct deposit</li>
      <li>A regular source of income</li>
      </ul>
      `
    },
    {
      title: "Can I request over the phone or in person?",
      description: `We're so sorry we can’t take calls. We don’t operate a call center because they are very expensive to staff and we want this service to be FREE of charge to you. We have made the form very simple to use and what's more is it's very discreet so please do go ahead and request a loan with complete confidence that we will look after your information and our mission is to introduce you to a loan offer quickly and efficiently.`,
    },
    {
      title: "I have terrible credit can I still request a loan?",
      description: `Yes, we have many lenders in our panel that work with customers who have less than perfect credit or no credit at all. What's more, by accepting a loan offer you may improve your credit score when you make your repayments on time. Many of our lenders report to the main credit reference agencies who will report your on time payments. Be aware though that they will also report bad repayment payments so please don’t request a loan if you can not afford to repay your loan.`,
    },
    {
      title: "What is a personal installment loan?",
      description: `A personal loan is a fixed term loan that can be used for a variety of purposes such as home improvements or vehicle repairs. The terms vary from 1-5 years. Rather than repaying this type of loan in one single installment, they are typically repaid over a set number of monthly installments.`,
    },
    {
      title: "How much can I borrow?",
      description: `This amount depends on the lender who issues the loan but typically it is upto $50,000.`,
    },
    {
      title: `What can I use the loan for?`,
      description: `There are no limitations for what you can do with your personal loan! You can use the funds for anything from debt consolidation to vacations.`
    },
    {
      title: `What are the fees for your service?`,
      description: `There are no fees to request a loan at www.lending-ground.com. Simply complete some information about yourself using the online form and get an answer within 3 minutes.
      The only cost you are responsible for comes after you review and agree to a lender’s terms, by signing a loan contract. Fees associated with the loan will vary depending on your state, the lender, and the amount of your personal loan.`
    },
    {
      title: `What is the APR?`,
      description: `The FEES and APR INTEREST RATES you will be charged are provided to you in a written document by the lender after you submit a loan request but before you are requested to digitally sign a loan agreement.`
    },
    {
      title: `How will I know if i'm approved?`,
      description: `Within a few minutes, you will receive a notification from a www.lending-ground.com approved lender letting you know if you’re approved! If you are presented with a loan offer, you will have the option to review the terms of the loan, which you may approve or declined. Remember, this is a FREE loan quoting service, there is no obligation until after you have agreed to receive a loan from one of our panel of lenders.`
    },
    {
      title: `How will I recieve the funds?`,
      description: `After a lender approves your personal loan, the funds will be transferred into your bank account.`
    },
    {
      title: `How and when do I repay my loan?`,
      description: `That depends upon the lender you choose. Generally, you and the approved lender will have an agreed amount deducted directly from your account on a date and time specified in your loan agreement.`
    },
    {
      title: `What if i'm late on a payment?`,
      description: `Lenders in the www.lending-ground.com network follow different policies regarding late payments. Most lenders apply a late fee for missing the loan payment deadline or asking to skip a payment. You should review and understand the late payment policy specified in the lender’s loan documents before accepting the loan terms and signing the loan agreement.`
    },
    {
      title: `How do you protect my information?`,
      description: `Your privacy and security are of the utmost importance to www.lending-ground.com! Our site offers encryption technology that allows you to use www.lending-ground.com with confidence. See our privacy policy for further details about our security.`
    },
    {
      title: `How long will it take for the funds to transfer to my bank account?`,
      description: `Once approved, funds can be transferred into your account. Depending on your banks processing time, funds will be transferred into your checking account as soon as the next business day after approval.`
    }
  ];

  const toggleTab = (index: number) => {
    if (openTabs.includes(index)) {
      setOpenTabs(openTabs.filter((i) => i !== index));
    } else {
      setOpenTabs([...openTabs, index]);
    }
  }

  return (
    <div className="-max-w-1366 mx-auto">
      <div className=" bg-gray-50 mt-16 p-8"  >
        <h2 className="text-center mb-8">FAQs</h2>
        {faqs.map((faq, index) => (
          <div key={index}
            className="flex flex-col pb-4 "
          >
            <div
              onClick={() => toggleTab(index)}
              className="flex items-start border-b pb-4 cursor-pointer">
              <h4 className="flex-1">
                {index + 1}. {faq.title}
              </h4>
              <div className="pt-2">
                <img src={chevronDownIcon} />
              </div>
            </div>
            <div className={openTabs.includes(index) ? "py-2" : "hidden"}>
              <p
                className={`p-4`}
                dangerouslySetInnerHTML={{ __html: faq.description }}>
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="p-8 md:p-0 flex flex-col items-center mt-12">
        <div className="flex-1 flex justify-center items-center">
          <img className="w-64" src={require("../../images/dream.png").default} />
        </div>
        <div className="flex-1 mt-8 md:mt-0 flex justify-center items-center">
          <div className="md:w-2/3 mx-auto text-center">
            <h2>You can make your future better and your dreams come true! Apply now to avail funds.</h2>
            <div className="flex justify-center my-4">
              <Link to="/apply">
                <FilledButton>
                  APPLY NOW
                </FilledButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
